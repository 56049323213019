import { withDependencies, named } from '@wix/thunderbolt-ioc'
import { MasterPageFeatureConfigSymbol } from '@wix/thunderbolt-symbols'
import { name } from './symbols'
import { IPopupsLinkUtilsAPI, PopupsMasterPageConfig } from './types'

const PopupsLinkUtilsAPIFactory = (masterPageConfig: PopupsMasterPageConfig): IPopupsLinkUtilsAPI => {
	return {
		getPopupPages() {
			return masterPageConfig.popupPages
		},
	}
}
export const PopupsLinkUtilsAPI = withDependencies(
	[named(MasterPageFeatureConfigSymbol, name)],
	PopupsLinkUtilsAPIFactory
)
